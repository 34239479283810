var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('Header',{attrs:{"title":"Your Products","subtitle":`Product Usage: ${this.$store.getters.getOrganizationState.sku_count}/${this.$store.getters.getOrganizationSettings.sku_limit}`,"divider":false,"options":[
      {
        'title': 'Create Product',
        'title_disabled': 'You have reached your sku limit.',
        'icon': 'mdi-plus',
        'disabled': !_vm.access.create_access || _vm.organization.sku_limit <= _vm.skuCount,
        'action': () => {this.$refs.create_dialog.show();}
      },
    ]}}),_c('v-divider',{staticClass:"mt-2 mb-5"}),_c('UnsavedChanges',{attrs:{"text":"Products Have Unsaved Changes","changes":_vm.changes,"changes_valid":_vm.changes_valid,"saveChanges":_vm.saveProducts,"discardChanges":_vm.discardChanges}}),_c('CreateProduct',{ref:"create_dialog",attrs:{"access":_vm.access}}),_c('ActionDialog',{ref:"delete_dialog",attrs:{"title":`Deleting Product${_vm.selectedProducts.length > 1 ? 's' : ''}`,"color":"error"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"error--text",domProps:{"innerHTML":_vm._s(`Are you sure you want to delete ${_vm.selectedProducts.length > 1 ? 'these' : 'this'} product${_vm.selectedProducts.length > 1 ? 's' : ''}.<br>Warning! You are about to delete <b>${_vm.selectedProducts.length}</b> product${_vm.selectedProducts.length > 1 ? 's' : ''}.
        ${_vm.selectedProducts.length > 1 ? 'These' : 'This'} product${_vm.selectedProducts.length > 1 ? 's' : ''} could be published on external sites.`)}})]},proxy:true}])}),_c('PublishProductDialog',{ref:"publish_dialog",attrs:{"products":_vm.publish_products,"websites":_vm.websites}}),_c('ProductsTable',{attrs:{"page":_vm.page,"pages":_vm.pages,"products":_vm.products,"brands":_vm.brands,"websites":_vm.websites,"selectedProducts":_vm.selectedProducts,"changes":_vm.changes,"changes_valid":_vm.changes_valid,"loading":_vm.loading,"editMode":_vm.editMode,"access":_vm.access,"search":_vm.search,"saveProducts":_vm.saveProducts,"goToProduct":_vm.goToProduct,"searchAllProducts":_vm.searchAllProducts,"showPublishDialog":_vm.showPublishDialog},on:{"update:page":function($event){_vm.page=$event},"update:selectedProducts":function($event){_vm.selectedProducts=$event},"update:selected-products":function($event){_vm.selectedProducts=$event},"update:changes_valid":function($event){_vm.changes_valid=$event},"update:editMode":function($event){_vm.editMode=$event},"update:edit-mode":function($event){_vm.editMode=$event},"delete":function($event){_vm.$refs.delete_dialog.showDialog().then(data => _vm.deleteSelectedProducts()).catch(() => {})}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }