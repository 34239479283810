<template>
  <v-container fluid>
    <Header 
      title="Your Products"
      :subtitle="`Product Usage: ${this.$store.getters.getOrganizationState.sku_count}/${this.$store.getters.getOrganizationSettings.sku_limit}`"
      :divider="false"
      :options="[
        {
          'title': 'Create Product',
          'title_disabled': 'You have reached your sku limit.',
          'icon': 'mdi-plus',
          'disabled': !access.create_access || organization.sku_limit <= skuCount,
          'action': () => {this.$refs.create_dialog.show();}
        },
      ]"
    ></Header>
    
    <v-divider class="mt-2 mb-5"/>

    <!-- Unsaved Changes Bar -->
    <UnsavedChanges
      text="Products Have Unsaved Changes"
      :changes="changes"
      :changes_valid="changes_valid"
      :saveChanges="saveProducts"
      :discardChanges="discardChanges"
    ></UnsavedChanges>

    <!-- Create Product Dialog -->
    <CreateProduct
      ref="create_dialog"
      :access="access"
    ></CreateProduct>

    <ActionDialog ref="delete_dialog" :title="`Deleting Product${selectedProducts.length > 1 ? 's' : ''}`" color="error">
      <template v-slot>
        <span class="error--text" v-html="`Are you sure you want to delete ${selectedProducts.length > 1 ? 'these' : 'this'} product${selectedProducts.length > 1 ? 's' : ''}.<br>Warning! You are about to delete <b>${selectedProducts.length}</b> product${selectedProducts.length > 1 ? 's' : ''}.
          ${selectedProducts.length > 1 ? 'These' : 'This'} product${selectedProducts.length > 1 ? 's' : ''} could be published on external sites.`"
        ></span>
      </template>
    </ActionDialog>

    <PublishProductDialog
      ref="publish_dialog"
      :products="publish_products"
      :websites="websites"
    ></PublishProductDialog>

    <ProductsTable
    :page.sync="page"
    :pages="pages"
    :products="products"
    :brands="brands"
    :websites="websites"
    :selectedProducts.sync="selectedProducts"
    :changes="changes"
    :changes_valid.sync="changes_valid"
    :loading="loading"
    :editMode.sync="editMode"
    :access="access"
    :search="search"
    :saveProducts="saveProducts"
    :goToProduct="goToProduct"
    :searchAllProducts="searchAllProducts"
    :showPublishDialog="showPublishDialog"
    @delete="$refs.delete_dialog.showDialog().then(data => deleteSelectedProducts()).catch(() => {})"
    ></ProductsTable>
  </v-container>
</template>

<script>
import { brandsCore } from "@/mixins/brandsMixin.js"
import { websitesCore } from "@/mixins/websitesMixin.js"
import { productsCore, productsLogic } from "@/mixins/productsMixin.js"
import Header from "@/components/Header.vue"
import UnsavedChanges from "@/components/UnsavedChanges.vue"
import ActionDialog from "@/components/ActionDialog.vue"
import CreateProduct from "@/components/products/CreateProductDialog.vue"
import PublishProductDialog from "@/components/PublishProductDialog.vue"
import ProductsTable from "@/components/products/ProductsTable.vue"
export default {
  mixins: [brandsCore, websitesCore, productsCore, productsLogic],
  data() {
    return {
      access: this.$store.getters.getAccessLevel[this.$route.path.split('/')[1]],
      changes_valid: true,
      editMode: false,
      selectedProducts: [],
      publish_products: [],
      organization: this.$store.getters.getOrganizationSettings,
    };
  },
  components: {
    Header,
    UnsavedChanges,
    ActionDialog,
    CreateProduct,
    PublishProductDialog,
    ProductsTable
  },
  methods: {
    async showPublishDialog() {
      await this.getVariantsForPublish();
      this.$refs.publish_dialog.show();
    },
    async getVariantsForPublish() {
      this.$store.dispatch("performingUpdate", true);
      let products = [];
      // Iterate through all selected products and get variant data
      for (var product of this.selectedProducts) {
        await this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/products/${product.product_id}/variants`)
          .then(result => {
            products.push({ ...product, variants: result.data });
          })
          .catch(err => {
            console.log(err);
          });
      }
      this.publish_products = products;
      this.$store.dispatch("performingUpdate", false);
    },
    getEditedProducts() {
      // Initialize values for getting required updates
      let old_products = this.$store.state.products.oldData.products;
      let edited_products = [];

      for (var i in this.products) {
        for (var key in this.products[i]) {
          if (this.products[i][key] !== old_products[i][key]) {
            if (!product) {
              var product = { product_id: this.products[i].product_id };
            }
            switch (key) {
              case 'brand_name':
                product['brand_id'] = this.brands.find(x => x.name === this.products[i]['brand_name']).brand_id;
                break;
              default:
                product[key] = this.products[i][key];
            }
          }
        }
        if (product) {
          edited_products.push(product);
          product = undefined;
        }
      }
      return edited_products;
    },
    saveProducts() {
      let edited_products = this.getEditedProducts();
      for (var product of edited_products) {
        this.updateProduct(product);
        this.updateProductLastUpdated(product.product_id);
      }
      this.editMode = false;
    },
    async deleteSelectedProducts() {
      for (var i in this.selectedProducts) {
        await this.deleteProduct({ product_id: this.selectedProducts[i].product_id, name: this.selectedProducts[i].name });
      }
      this.selectedProducts = [];
      this.searchAllProducts();

    }
  }
};
</script>