<template>
  <v-expand-transition>
    <v-app-bar v-if="changes" color="grey darken-3" dark fixed>
      <v-toolbar-title style="margin-left: 12px;">{{ text }}</v-toolbar-title>
      
      <v-spacer></v-spacer>
      
      <v-btn class="mr-4" outlined @click="discardChanges">Discard</v-btn>
      <v-btn color="#008699" class="mr-4" @click="saveChanges" :disabled="!changes_valid" :loading="$store.getters.getPerformingUpdate">Save</v-btn>
    </v-app-bar>
  </v-expand-transition>
</template>

<script>
export default {
  props: {
    text: String,
    changes: Boolean,
    changes_valid: Boolean,
    saveChanges: Function,
    discardChanges: Function,
  }
}
</script>