<template>
  <v-card elevation=4 class="rounded-lg" outlined>
    <v-card-title class="pt-3 pb-2">
      <ProductsTableHeader
        :search="search"
        :brands="brands"
        :websites="websites"
        :editMode="editMode"
        :searchAllProducts="searchAllProducts"
      ></ProductsTableHeader>
    </v-card-title>

    <v-divider/>

    <v-data-table
      :value="selectedProducts"
      :headers="headers"
      :items="products"
      :items-per-page="20"
      item-key="product_id"
      :sort-by="search.sort_by"
      :sort-desc="search.sort_desc"
      :loading="loading"
      show-select
      hide-default-footer
      class="elevation-0 hover-cursor"
      disable-sort
      @click:row="(editMode ? () => {} : (value) => goToProduct(value.product_id))"
      @input="$emit('update:selectedProducts', $event)"
    >
      <template v-slot:top>
        <v-toolbar dense flat class="normal-cursor">
          <v-btn-toggle v-if="selectedProducts.length" multiple max=0 dense class="rounded-lg mt-3 ml-n1">
            <v-btn @click="$emit('update:selectedProducts', [])" style="padding-left: 4px;"><v-checkbox input-value="selectedProducts" hide-details :indeterminate="selectedProducts.length !== products.length" color="rgba(0, 134, 153)"></v-checkbox>{{selectedProducts.length}} Selected</v-btn>
            <v-btn @click="showPublishDialog()" v-if="access.update_access">Publish Products</v-btn>
            <v-btn @click="$emit('delete')" v-if="access.delete_access">Delete Products</v-btn>
            <v-btn @click="exportProducts([], selectedProducts.map(product => product.product_id))" >Export Products</v-btn>
          </v-btn-toggle>
          <v-spacer/>
          <v-switch label="Edit Mode" :value="editMode" inset hide-details @click="()=>{$emit('update:editMode', !editMode); editMode ? saveProducts() : {} }"></v-switch>
        </v-toolbar>
      </template>

      <template v-slot:[`header.name`]="{ header }">
        <v-container @click="(editMode ? () => {} : () => sortProducts(header.value))">
          {{header.text}}
          <v-icon v-if="search.sort_by === header.value" small :style="`${search.sort_desc ? 'transform: rotate(180deg)' : ''}`">mdi-arrow-up</v-icon>
        </v-container>
      </template>

      <template v-slot:[`header.brand_name`]="{ header }">
        <v-container @click="(editMode ? () => {} : () => sortProducts(header.value))">
          {{header.text}}
          <v-icon v-if="search.sort_by === header.value" small :style="`${search.sort_desc ? 'transform: rotate(180deg)' : ''}`">mdi-arrow-up</v-icon>
        </v-container>
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <v-img :src="item.image_url" gradient="to bottom, rgba(0,0,0,.03), rgba(0,0,0,.05)" height="45px" width="45px" class="image-border" contain></v-img>
      </template>

      <template v-slot:[`item.name`]="{item}">
        <td class="px-0" style="max-width: 300px">
          <a v-if="!editMode" :href="'/products/'+item.product_id" @click.prevent>
            <div class="txt">
              <div class="text-truncate txt font-weight-bold">{{item.name}}</div>
            </div>
          </a>
          <div v-else>
            <v-text-field v-model="item.name" outlined dense hide-details="auto" style="margin: 0; padding: 0;" :rules="product_name_rules"></v-text-field>
          </div>
        </td>
      </template>

      <template v-slot:[`item.brand_name`]="{item}">
        <div v-if="!editMode">
          <template v-if="item.brand_name">{{item.brand_name}}</template>
          <template v-else>-</template>
        </div>
        <div v-else>
          <v-select v-model="item.brand_name" :items="brands" outlined dense item-text="name" item-value="name" hide-details style="margin: 0; padding: 0;"></v-select>
        </div>
      </template>

      <template v-slot:[`item.variants`]="{item}">
        <template>{{item.variants}} variant{{item.variants > 1 ? "s" : ""}}</template>
      </template>

      <template v-slot:[`item.variant_type`]="{item}">
        <div v-if="!editMode">
          <template>{{item.variant_type ? item.variant_type : "-"}}</template>
        </div>
        <div v-else>
          <v-combobox v-if="item.variants > 1" v-model="item.variant_type" :items="['Colour', 'Size', 'Material', 'Style', 'Device']" outlined dense @input.native="item.variant_type=$event.srcElement.value" hide-details="auto" style="margin: 0; padding: 0;" :rules="variant_type_rules"></v-combobox>
        </div>
      </template>
    </v-data-table>
    <v-divider/>
    <div class="text-center">
      <v-container class="pa-0">
        <v-row justify="center">
          <v-col cols=11>
            <v-container class="max-width pa-0">
              <v-pagination :value="page" :length="pages" @input="$emit('update:page', $event)"></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import exportsMixin from "@/mixins/exportsMixin.js";
import ProductsTableHeader from "@/components/products/ProductsTableHeader.vue";
export default {
  mixins: [exportsMixin],
  props: {
    page: Number,
    pages: Number,
    products: Array,
    brands: Array,
    websites: Array,
    selectedProducts: Array,
    changes: Boolean,
    changes_valid: Boolean,
    loading: Boolean,
    editMode: Boolean,
    access: Object,
    search: Object,
    saveProducts: Function,
    goToProduct: Function,
    searchAllProducts: Function,
    showPublishDialog: Function,
  },
  data() {
    return {
      headers: [
        { value: 'image', sortable: false, width: "77px" },
        { text: 'Name', value: 'name' },
        { text: 'Brand', value: 'brand_name', align: 'center' },
        { text: 'Variants', value: 'variants', align: 'center' },
        { text: 'Variant Type', value: 'variant_type', align: 'center' },
      ],
      product_name_rules: [v => !!v || 'Name is required'],
      variant_type_rules: [v => !!v || 'Variant Type is required'],
    }
  },
  components: {
    ProductsTableHeader,
  },
  methods: {
    sortProducts(type) {
      // Initially remove the sort from search
      delete this.search.sort;
      // If sort descending is not true
      if (!this.search.sort_desc) {
        // Set the sort by and descending fields (for table on reload)
        this.search.sort_desc = this.search.sort_desc === undefined ? false : true;
        this.search.sort_by = type;
        // Set the sort field (for backend SQL)
        this.search.sort = `${type}+${this.search.sort_desc ? 'desc' : 'asc'}`;
      }
      // Search all the products with the updated search query
      this.searchAllProducts();
    }
  }
}
</script>

<style scoped>
a { 
  text-decoration: none;
  color: rgb(0, 0, 0) !important;
}
.txt:hover {
  text-decoration: underline;
}
</style>