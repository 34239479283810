<template>
  <v-row>
    <v-col class="pl-2 pr-1">
      <v-text-field
        label="Name"
        v-model="search.name"
        outlined
        clearable
        dense
        hide-details
        class="rounded-lg"
        :disabled="editMode"
        @keyup.enter="searchAllProducts"
        @input="debounceSearchAllProducts"
        @click:clear="()=>{delete search.name; searchAllProducts()}"
      />
    </v-col>
    <v-col class="px-1">
      <v-text-field
        label="SKU"
        v-model="search.sku"
        outlined
        clearable
        dense
        hide-details
        class="rounded-lg"
        :disabled="editMode"
        @keyup.enter="searchAllProducts"
        @input="debounceSearchAllProducts"
        @click:clear="()=>{delete search.sku; searchAllProducts()}"
      />
    </v-col>
    <v-col class="px-1">
      <v-select
        label="Brand"
        v-model="search.brand"
        :items="brands"
        outlined
        clearable
        dense
        hide-details
        class="rounded-lg"
        item-text="name"
        item-value="brand_id"
        :disabled="editMode"
        @change="searchAllProducts()"
      />
    </v-col>
    <v-col class="px-1">
      <v-combobox
        label="Variant Type"
        v-model="search.variant_type"
        :items="['Colour', 'Size', 'Material', 'Style', 'Device']"
        outlined
        clearable
        dense
        hide-details
        class="rounded-lg"
        :disabled="editMode"
        @change="searchAllProducts()"
      />
    </v-col>
    <v-col class="pl-1 pr-2">
      <v-select
        label="Website"
        :items="websites"
        v-model="search.website"
        outlined
        clearable
        dense
        hide-details
        class="rounded-lg"
        item-text="name"
        item-value="website_id"
        :disabled="editMode"
        @change="search.status ? search.status = search.status : search.status = statuses[0].value; searchAllProducts()"
      >
        <template v-slot:prepend-item>
          <v-list-item class="pb-2 px-2">
            <v-select label="Status" v-model="search.status" :items="statuses" @change="search.website ? searchAllProducts() : {}" outlined dense hide-details></v-select>
          </v-list-item>
          <v-divider/>
        </template>
      </v-select>
    </v-col>
    <v-col cols="auto" class="pl-0 pr-2 ma-auto">
      <v-btn class="rounded-lg px-1" color="primary" text @click="()=>{Object.keys(search).forEach(key => delete search[key]); searchAllProducts()}">Clear<v-icon small>mdi-close</v-icon></v-btn>
    </v-col>
  </v-row>
</template>

<script>
import _debounce from 'lodash/debounce';
export default {
  props: {
    search: Object,
    brands: Array,
    websites: Array,
    editMode: Boolean,
    searchAllProducts: Function,
  },
  data() {
    return {
      statuses: [
        { text: 'Published', value: 'published' },
        { text: 'Published (Active Only)', value: 'active' },
        { text: 'Published (Inactive Only)', value: 'inactive' },
        { text: 'Unpublished', value: 'unpublished' },
      ]
    }
  },
  methods: {
    debounceSearchAllProducts: _debounce(function () {
      this.searchAllProducts();
    }, 600)
  }
}
</script>